<template>
  <div class="pb-12 mb-12">
    <v-row v-if="user.profile && offboarding">
      <v-col md="9" sm="12">
        <v-row class="pt-5 pb-2">
          <v-col>
            <div>
              <h2 class="primary--text">Completion</h2>
              <p v-if="offboarding.completed_at">
                {{ offboarding.completed_at | formatedDate }}
              </p>
              <p v-else>
                N/A
              </p>
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-row>
          <v-col>
            <offboarding-pictures
              :loading="loading"
              :user-profile="user.profile"
              :user-offboarding="offboarding"
            />
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="pt-5 pb-2">
          <v-col>
            <div>
              <h2 class="primary--text">Performance Summary</h2>
              <p>How she performed during the course of the program</p>
            </div>

            <div>
              <div class="d-flex justify-space-between">
                <h4 class="mb-4">Workouts Completed</h4>
                <v-skeleton-loader v-if="loading" width="30" type="text" />
                <p v-else class="primary--text">
                  {{ performance.workouts | round }}%
                </p>
              </div>

              <div class="d-flex justify-space-between">
                <h4 class="mb-4">Average Daily Hydration</h4>
                <v-skeleton-loader v-if="loading" type="text" width="50" />
                <p v-else class="primary--text">
                  {{ performance.hydrations | round }} ml
                </p>
              </div>

              <div class="d-flex justify-space-between">
                <h4 class="mb-4">Average Daily Steps</h4>
                <v-skeleton-loader v-if="loading" type="text" width="40" />
                <p v-else class="primary--text">
                  {{ performance.steps | round }}
                </p>
              </div>

              <div class="d-flex justify-space-between">
                <h4 class="mb-4">Overall Calorie Compliance</h4>
                <v-skeleton-loader v-if="loading" type="text" width="30" />
                <p v-else class="primary--text">
                  {{ performance.nutrition | round }}%
                </p>
              </div>

              <div class="d-flex justify-space-between">
                <h4 class="mb-4">Average Hours of Sleep per Night</h4>
                <v-skeleton-loader v-if="loading" type="text" width="30" />
                <p v-else class="primary--text">
                  {{ performance.sleeps | round }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="pt-5 pb-2">
          <v-col>
            <h2 class="primary--text">Goal Reflection</h2>
            <p>
              Her consistency to the goals she set during the start of the
              program and what she wants to do next.
            </p>

            <div class="mt-5">
              <h4 class="mb-1">Goal Consistency</h4>

              <v-skeleton-loader
                v-if="loading"
                type="paragraph"
                width="400"
                class="mt-3"
              />

              <p v-else>{{ offboarding.goal_consistency_label || 'N/A' }}</p>
            </div>

            <div class="mt-5">
              <h4 class="mb-1">New Goals</h4>

              <list-skeleton class="mt-3" v-if="loading" />

              <template v-else>
                <p v-if="empty(offboarding.new_goals)">None</p>
                <p
                  v-else
                  :key="goal"
                  v-for="goal in offboarding.new_goals"
                  class="mb-0"
                >
                  • {{ goal }}
                </p>
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row class="pt-5 pb-2">
          <v-col>
            <h2 class="primary--text">Testimonial</h2>
            <p>
              What she experienced during the course of the program
            </p>

            <div class="mt-5">
              <h4 class="mb-1">Testimonial Social Sharing Consent</h4>

              <v-skeleton-loader
                v-if="loading"
                type="paragraph"
                width="400"
                class="mt-3"
              />

              <p v-else>{{ offboarding.testimonial_sharing ? 'Yes' : 'No' }}</p>
            </div>

            <div class="mt-5">
              <h4 class="mb-1">Testimonial</h4>

              <v-skeleton-loader
                v-if="loading"
                type="paragraph"
                width="400"
                class="mt-3"
              />

              <p v-else>{{ offboarding.testimonial || 'N/A' }}</p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="9" sm="12">
        <no-list details="No Offboarding Data" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListSkeleton from '@/components/elements/ListSkeleton'
import OffboardingPictures from './OffboardingPictures'
import thumb from '@/assets/images/thumbnail.png'
import NoList from '@/components/elements/NoList'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserOffboarding',

  components: {
    OffboardingPictures,
    ListSkeleton,
    NoList,
  },

  data() {
    return {
      offboarding: null,
      performance: null,
      loading: false,
      thumb,
    }
  },

  watch: {
    selectedProgram: async function(program, oldProgram) {
      if (oldProgram && program.id !== oldProgram?.id) {
        this.fetchProgress()
      }
    },
  },

  computed: {
    ...mapGetters({
      user: 'users/currentSelectedUserProfile',
      selectedProgram: 'users/currentSelectedProgram',
    }),
  },

  created() {
    this.fetchProgress()
  },

  methods: {
    ...mapActions({
      getProgress: 'users/getUserOffboardingProgress',
    }),

    empty(value) {
      return !value || value.length === 0
    },

    async fetchProgress() {
      try {
        if (!this.selectedProgram.id || !this.user.id || this.loading) return

        this.loading = true

        const data = await this.getProgress({
          userId: this.$route.params.id,
          programId: this.selectedProgram.user_program.id,
        })

        this.offboarding = data.offboarding
        this.performance = data.performance

        await this.$sleep()

        this.loading = false
      } catch (error) {
        this.offboarding = null
        this.performance = null
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>
