<template>
  <div>
    <v-skeleton-loader
      :key="`${key}-${width}`"
      v-for="(width, key) in generateList()"
      type="text"
      :width="width"
    />
  </div>
</template>

<script>
import { random } from 'lodash'

export default {
  name: 'ListSkeleton',

  props: {
    maxItems: {
      type: Number,
      default: 3,
    },
  },

  methods: {
    generateList() {
      return Array.from(Array(random(1, this.maxItems)).keys()).map(() =>
        random(200, 300)
      )
    },
  },
}
</script>
