<template>
  <!-- The author of the package places the before image in the right side, so it's reversed -->
  <image-compare
    class="image-comparison"
    :before="after | imageUrl"
    :after="before | imageUrl"
  >
    <v-icon slot="icon-left">{{ icons.left }}</v-icon>
    <v-icon slot="icon-right">{{ icons.right }}</v-icon>
  </image-compare>
</template>

<script>
import { isObject } from 'lodash'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  name: 'ImageComparisonSlider',

  props: {
    before: {
      type: [Object, String],
      default: '',
    },
    after: {
      type: [Object, String],
      default: '',
    },
  },

  data() {
    return {
      icons: {
        left: mdiChevronLeft,
        right: mdiChevronRight,
      },
    }
  },

  filters: {
    imageUrl: (source) => (isObject(source) ? source.thumb_url : source),
  },
}
</script>

<style lang="scss">
.image-comparison {
  .v-icon > svg {
    color: white;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -ms-user-drag: none;
  }
}
</style>
