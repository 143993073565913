<template>
  <div>
    <h2 class="primary--text">Final Measurement and Photos</h2>

    <div class="mt-5">
      <h4 class="mb-1">Offboarding Weight</h4>

      <v-skeleton-loader
        v-if="loading"
        type="paragraph"
        width="400"
        class="mt-3"
      />

      <p v-else>{{ userOffboarding.weight }} kg</p>
    </div>

    <h4 class="mb-1">Pictures</h4>
    <v-row>
      <v-col cols="4">
        <v-skeleton-loader v-if="loading" type="image" tile :height="350" />
        <image-comparison-slider :before="back.before" :after="back.after" />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader v-if="loading" type="image" tile :height="350" />
        <image-comparison-slider :before="front.before" :after="front.after" />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader v-if="loading" type="image" tile :height="350" />
        <image-comparison-slider :before="side.before" :after="side.after" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageComparisonSlider from '@/components/elements/ImageComparisonSlider'
import thumb from '@/assets/images/thumbnail.png'

export default {
  name: 'OffboardingPictures',

  props: {
    userOffboarding: Object,
    userProfile: Object,
    loading: Boolean,
  },

  components: {
    ImageComparisonSlider,
  },

  computed: {
    front() {
      return {
        before: this.$get(
          this.userProfile,
          'initial_front_photo.thumb_url',
          thumb
        ),
        after: this.$get(
          this.userOffboarding,
          'body_photos.front.thumb_url',
          thumb
        ),
      }
    },

    back() {
      return {
        before: this.$get(
          this.userProfile,
          'initial_back_photo.thumb_url',
          thumb
        ),
        after: this.$get(
          this.userOffboarding,
          'body_photos.back.thumb_url',
          thumb
        ),
      }
    },

    side() {
      return {
        before: this.$get(
          this.userProfile,
          'initial_side_photo.thumb_url',
          thumb
        ),
        after: this.$get(
          this.userOffboarding,
          'body_photos.side.thumb_url',
          thumb
        ),
      }
    },
  },
}
</script>
